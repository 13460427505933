<template>
  <div>
    <div class="d-flex justify-start align-center">
      <div class="demo-space-x">
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <span class="d-block text-caption black--text">Sekolah</span>
        <span
          class="d-block text-subtitle-1 font-weight-bold black--text"
          style="line-height: 1.5rem !important"
        >
          {{ schoolName }}
        </span>
      </div>
    </div>
    <div class="mt-6">
      <v-row>
        <v-col cols="12">
          <MainCard v-if="!isLoadingTable">
            <template v-slot:body>
              <DataTablePagination
                :page="page"
                :items="announcements"
                :total-items="totalItems"
                :total-pages.sync="totalPages"
                :headers="headers"
                :no-add-button="noAddButton"
                :is-loading="isLoadingData"
                :items-per-page="5"
                title="Pengumuman"
                @change-page="paginationHandler"
              >
              </DataTablePagination>
            </template>
          </MainCard>
          <SkeletonLoaderTable v-else></SkeletonLoaderTable>
          <v-card>
              <v-card-text class="text-center">
                <router-link
                  to="/dashboard-headmaster/announcement"
                  class="text-decoration-none"
                >
                  <a class="text-subtitle-2">Lihat Semua</a>
                </router-link>

              </v-card-text>
            </v-card>
        </v-col>
        <v-col
          xl="5"
          lg="5"
          md="5"
          sm="12"
          cols="12"
        >
          <v-card :loading="isLoadingCard">
            <v-card-title class="d-flex align-center justify-space-between">
              <h3 class="text-subtitle-1 black--text font-weight-bold">Absensi Pegawai</h3>
              <div>
                <v-autocomplete
                  :label="dateNow"
                  outlined
                  :items="date"
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details="none"
                  @change="handleFilter($event)"
                ></v-autocomplete>
              </div>

            </v-card-title>
            <v-card-text>
              <apex-chart-expense-ratio :count="count"></apex-chart-expense-ratio>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          xl="7"
          lg="7"
          md="7"
          sm="12"
          cols="12">
          <v-row>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="6"
              cols="12"
              v-for="card,index in cards"
              :key="index"
            >
              <v-card :loading="isLoadingCard">
                <v-card-title>
                  <v-avatar
                    :color="card.color"
                  >
                    <v-container>
                      <v-img
                        :src="icons.iconUser"
                        width="28"
                      ></v-img>
                    </v-container>
                  </v-avatar>
                </v-card-title>
                <v-card-text>
                  <p class="text-subtitle-1 black--text">{{ card.title }}</p>
                  <p class="text-h5 font-weight-bold black--text">{{ card.total }}</p>
                  <router-link
                    :to="card.url"
                    class="text-decoration-none"
                  >
                    <a class="text-subtitle-2">Lihat Detail</a>
                  </router-link>

                </v-card-text>
              </v-card>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import iconSchool from '@/assets/icons/courthouse.svg'
import iconBook from '@/assets/icons/white/book.svg'
import iconDanger from '@/assets/icons/white/danger.svg'
import iconDevices from '@/assets/icons/white/devices.svg'
import iconMessage from '@/assets/icons/white/messages-2.svg'
import iconMonitor from '@/assets/icons/white/monitor.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconUser from '@/assets/icons/white/user.svg'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiTownHall } from '@mdi/js'
import ApexChartExpenseRatio from './ApexChartExpenseRatio.vue'

export default {
  name: 'DashboardHeadmaster',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
    ApexChartExpenseRatio,
  },
  data() {
    return {
      valueRadio: [
        {
          index: 1,
          status: 'Permanen',
        },
        {
          index: 2,
          status: 'Jangka Waktu',
        },
      ],
      count: [0, 0],
      icons: {
        iconMonitor,
        iconSchool,
        iconUser,
        iconDanger,
        iconBook,
        iconDevices,
        iconMessage,
        iconMusic,
        iconPlay,
        iconReceipt,
        mdiTownHall,
      },
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      cards: [
        {
          title: 'Total Siswa',
          keyName: 'student_count',
          total: 0,
          icon: iconUser,
          url: 'student-headmaster',
          color: '#00913E',
        },
        {
          title: 'Total Pegawai',
          keyName: 'employee_count',
          total: 0,
          icon: iconUser,
          url: 'functionary-headmaster',
          color: '#93278F',
        },
        {
          title: 'Total Guru',
          keyName: 'teacher_count',
          total: 0,
          icon: iconUser,
          url: 'functionary-headmaster',
          color: '#E66C2E',
        },
      ],
      service: 'announcementhod',
      noAddButton: true,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingTable: true,
      isLoadingData: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'JUDUL', value: 'title' },
        { text: 'PESAN', value: 'content' },
        { text: 'SET WAKTU', value: 'status' },
        { text: 'TANGGAL  UPLOAD', value: 'created' },
        { text: 'TAMPIL PADA', value: 'start_date' },
      ],
      announcements: [],
      schoolName: '',
      paramsDate: '',
      dateNow: '',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      isLoadingCard: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.listAnnouncement(this.filterQuery)
      },
    },
  },
  beforeMount() {
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
    this.kadis_uuid = JSON.parse(localStorage.getItem('user')).user.details[0].kadis_uuid
  },
  mounted() {
    this.listAnnouncement()
    this.schoolName = JSON.parse(localStorage.getItem('user')).user.details[0].school.name
    this.getDataCount({ date: this.paramsDate })
  },
  methods: {
    async listAnnouncement(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('announcementhod', {
        ...params,
        kadis_uuid: this.kadis_uuid,
        page: this.page,
      }).then(({ data }) => {
        this.announcements = data.data.map((announcement, index) => ({
          ...announcement,
          index: index + 1,
          created: this.getFormatDate(announcement.created_at),
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async handleFilter(id) {
      await this.getDataCount({ date: id })
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    async getDataCount(params) {
      this.isLoadingCard = true
      await this.$services.ApiServices.list('dashboardheadmaster', { ...params }).then(
        ({ data }) => {
          Object.keys(data.data).forEach((key, index) => {
            this.cards.forEach(el => {
              if (el.keyName === key) {
                el.total = data.data[key]
              }
            })
          })
          this.announcement = data.data.latest_announcement_kadis
          // this.count = Object.values(data.data.absen)
        },
        err => console.error(err),
      )
      this.isLoadingCard = false
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `${createdDate.getDate()}`
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const month = `${monthNames[createdDate.getMonth()]}`
      const year = createdDate.getFullYear()

      return `${day.slice(-2)} ${month} ${year}`
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
  },
}
</script>

<style>
</style>
